(<template>
  <div :class="'task-status-info '+ status">
    <h3 v-if="title"
        class="task-status-info__title ellipsis">
      {{ title }}
    </h3>
    <p class="task-status-info__text">{{ message }}</p>
  </div>
</template>)

<script>
  export default {
    name: 'task-status-info',
    props: {
      status: {
        type: String,
        default: ''
      },
      subtaskTypes: {
        type: Array,
        default: () => []
      }
    },
    computed: {
      title() {
        if (this.status == 'cancelled') return this.$pgettext('translation', 'This project has been cancelled');
        else if (this.status == 'waiting_for_customer') return this.$pgettext('translation', 'This project is waiting for customer');
        else return this.subtaskTypesMessage.title;
      },
      subtaskTypesMessage() {
        const messageObj = {
          title: this.$pgettext('translation', 'Translated Documents'),
          message: this.$pgettext('translation', 'The finished documents will be displayed here')
        };

        if (this.subtaskTypes.includes('correction')) {
          messageObj.title = this.$pgettext('translation', 'Corrected documents');
          messageObj.message = this.$pgettext('translation', 'Documents that have been through correction will be displayed here');
        }

        if (this.subtaskTypes.includes('in_person')) {
          messageObj.title = '';
          messageObj.message = this.$pgettext('translation', 'We have received your order and will shortly be in contact about the address and other details, so that we may find the best translator for your project.');
        }

        return messageObj;
      },
      message() {
        if (this.status == 'cancelled' || this.status == 'waiting_for_customer') return this.$pgettext('translation', 'Please contact your project manager if you have questions concerning this project.');
        else return this.subtaskTypesMessage.message;
      },
    }
  };
</script>

<style scoped>

.task-status-info {
  margin-bottom: 15px;
  padding: 16px;
  border: solid 2px #e5e5e5;
  border-radius: 6px;
}

.cancelled {
  color: #f04;
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.task-status-info__title {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 16px;
}

.task-status-info__title span:not(:first-child) {
  padding-left: 20px;
}

.task-status-info__text {
  font-size: 12px;
}
</style>
