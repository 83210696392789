<template>
  <div class="assigned-translator">
    <div class="assigned-translator__wrapper">
      <task-status-info v-if="showTaskInfo"
                        :status="projectStatus" />
      <no-chat-invite v-if="quoteNotAccepted"
                      :booking-step-list="bookingSteps"
                      :booking-process-list="bookingProcesses" />
      <template v-if="showAcceptedBlock">
        <h2 class="assigned-translator__title">{{ assignedTranslatorsText }}</h2>
        <div class="assigned-translator__wrap-info">
          <div v-for="(task, index) in filteredTasks"
               :key="index"
               class="assigned-translator__info">
            <div class="assigned-translator__info-content">
              <div class="assigned-translator__info-description">
                <div class="assigned-translator__info-translator">
                  <div v-if="showIfSellerName(task)"
                       class="assigned-translator__info-avatar-cont">
                    <tt-avatar class="assigned-translator__info-avatar"
                               :avatar="task.seller.avatar" />
                  </div>
                  <div class="assigned-translator__info-row">
                    <p v-if="showIfSellerName(task)"
                       class="assigned-translator__info-title">
                      {{ task.seller.name }}
                    </p>
                    <p v-if="showSellerEmail && task.seller.email"
                       class="assigned-translator__info-subtitle assigned-translator__email">{{ task.seller.email }}</p>
                    <p class="assigned-translator__info-subtitle"><span v-if="task.seller.gender">{{ genderName(task.seller.gender) }} | </span> <span v-if="task.seller.country && task.seller.city">{{ task.seller.city }}, {{ task.seller.country }}</span></p>
                  </div>
                </div>
                <!--                  TODO: TO be added on v1.1-->
                <!--              <div class="assigned-translator__info-row">-->
                <!--                <p class="assigned-translator__info-title">-->
                <!--                  {{ languageName(project.sourceLanguageId) }} - {{ languageName(task.targetLanguageId) }}-->
                <!--                </p>-->
                <!--                <p class="assigned-translator__info-subtitle">{{ stateAuthText }}</p>-->
                <!--              </div>-->
                <div v-if="task.finalAttachments"
                     class="assigned-translator__documents project-documents">
                  <h3 class="project-documents__title"><span>{{ translatedDocText }}</span> <span>{{ languageName(task.targetLanguageId) }}</span></h3>
                  <p class="project-documents__sub-text">{{ translatedHelpText }}</p>
                  <!--                 TODO: TO be added on v1.1-->
                  <!--                <button type="button"-->
                  <!--                        class="new-assignment-btn"-->
                  <!--                        @click="decriptFile(task.finalAttachments)">{{ downloadDocText }}</button>-->
                  <documents-list :documents="task.finalAttachments" />
                </div>
                <task-status-info v-else
                                  class="assigned-translator__documents"
                                  :status="project.status"
                                  :subtask-types="project.subtaskTypes" />
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
  import DocumentsList from '@/components/assignment_components/one_assignment/DocumentsList';
  import TaskStatusInfo from '@/components/project_components/one_project/shared_components/TaskStatusInfo';
  import {mapGetters} from 'vuex';
  import EmptyChatInvite from '@/components/assignment_components/one_assignment/no_chat_info/EmptyChatInvite';

  export default {
    components: {
      'documents-list': DocumentsList,
      'task-status-info': TaskStatusInfo,
      'no-chat-invite': EmptyChatInvite
    },
    computed: {
      ...mapGetters('OneProjectStore', ['project']),
      ...mapGetters('UserInfoStore', ['showSellerEmail']),
      languageList() {
        return this.$store.state.selectsData.language || [];
      },
      bookingSteps() {
        return [
          this.$pgettext('translation', 'We are in the process of finding a translator for your project!')
        ];
      },
      bookingProcesses() {
        return [{
          class: 'empty-chat__process--left',
          text: this.$gettext('Booking<br/>registered')
        }, {
          class: 'empty-chat__process--right',
          text: this.$pgettext('translation', 'Project manager is looking<br/> through the requirements')
        }, {
          class: 'empty-chat__process--left',
          text: this.$pgettext('translation', 'Translator is<br/> confirmed')
        }, {
          class: 'empty-chat__process--center',
          text: this.$pgettext('translation', 'Translation Begins')
        }];
      },
      tasks() { return this.project.tasks; },
      filteredTasks() { return this.tasks?.filter((task) => { return task.status !== 'archived'; }); },
      waitingForCustomer() { return this.project?.waitingForCustomer; },
      projectStatus() { return this.waitingForCustomer ? 'waiting_for_customer' : this.project.status; },
      hasAssignedTranslator() { return this.filteredTasks.some((task) => task.seller); },
      showDocumentsHintBlock() {
        return this.project.status === 'quote_requested' || this.project.status === 'quote_sent' || this.project.status === 'quote_rejected' || this.project.status === 'quote_accepted';
      },
      showAcceptedBlock() {
        return this.projectStatus === 'quote_accepted'
          || this.projectStatus === 'in_progress'
          || this.projectStatus === 'finished'
          || (this.projectStatus === 'completed' && this.hasAssignedTranslator);
      },
      quoteNotAccepted() {
        return this.projectStatus === 'quote_requested' || this.projectStatus === 'quote_sent' || this.projectStatus === 'quote_rejected';
      },
      showTaskInfo() {
        const validStatuses = ['waiting_for_customer', 'cancelled'];
        return validStatuses.includes(this.projectStatus);
      },
      downloadDocText() { return this.$pgettext('translation', 'Download Documents'); },
      translatedDocText() { return this.$pgettext('translation', 'Translated Documents'); },
      translatedHelpText() { return this.$pgettext('translation', 'Download the file(s) here:'); },
      assignedTranslatorsText() { return this.$pgettext('translation', 'Assigned translator(s)'); },
      stateAuthText() { return this.$pgettext('translation', 'State Authorized Translator'); }
    },
    methods: {
      decriptFile(links) {
        links?.forEach((link) => {
          this.$store.dispatch('decriptFile', link).then((newLink) => {
            window.location.href = newLink;
          });
        });
      },
      languageName(langId) {
        return this.languageList?.find((lang) => lang.id === langId).name;
      },
      genderName(genderId) {
        const genders = this.$helperData.getSelectGenders(this);
        const gender = genders.find(({id}) => id == genderId) || {};

        return gender.name;
      },
      showIfSellerName(task) {
        return task.seller?.name;
      },
    }
  };
</script>

<style>
.assigned-translator__wrapper .empty-chat {
  padding: 5px;
  font-size: 13px;
}

.assigned-translator__wrapper .empty-chat__processes {
  max-width: 400px;
}

.assigned-translator__wrapper .empty-chat__process {
  padding-top: 10px;
}

.assigned-translator__wrapper .empty-chat__steps {
  list-style: none;
  font-size: 14px;
}

.assigned-translator__wrapper .empty-chat__title {
  color: black;
  font-size: 16px;
}

.assigned-translator__wrapper .empty-chat__process-text {
  padding: 3px 5px;
}
</style>

<style scoped>
.project-documents {
  margin-bottom: 15px;
  padding: 16px;
  border: solid 2px #bfbece;
  border-radius: 6px;
  -webkit-box-shadow: 4px 6px 15px -9px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 4px 6px 15px -9px rgba(0, 0, 0, 0.75);
  box-shadow: 4px 6px 15px -9px rgba(0, 0, 0, 0.75);
}

.project-documents__title {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 16px;
  color: #2b255c;
  font-size: 18px;
}

.project-documents__sub-text {
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 15px;
}

.assigned-translator {
  background-color: #fff;
}

.assigned-translator__wrapper {
  padding: 20px 30px;
}

.assigned-translator__title {
  margin-bottom: 16px;
}

.assigned-translator__info {
  display: flex;
  align-items: center;
  width: 100%;
}

.assigned-translator__info:not(:first-child) {
  margin-top: 33px;
  padding-top: 20px;
  border-top: solid 2px #e5e5e5;
}

.assigned-translator__info-content {
  display: flex;
  width: 100%;
}

.assigned-translator__info-description {
  flex-grow: 1;
  padding: 0 20px;
}

.assigned-translator__info-translator {
  display: flex;
}

.assigned-translator__info-avatar {
  flex-shrink: 0;
  width: 60px;
  height: 60px;
  margin-right: 20px;
}

.assigned-translator__info-row:nth-child(1n + 2) {
  margin-top: 10px;
}

.assigned-translator__info-title {
  display: inline-block;
  font-weight: bold;
}

.assigned-translator__info-subtitle {
  display: block;
  color: #7a829d;
  font-size: 10px;
}

.assigned-translator__email {
  color: #000;
}

.new-assignment-btn {
  margin-right: 0;
  margin-bottom: 16px;
  margin-left: 0;
}

.assigned-translator__documents {
  margin-top: 20px;
  background-color: #ccf6e4;
}

@media (max-width: 767px) {
  .assigned-translator__info {
    padding-top: 20px;
  }
}

@media (max-width: 500px) {
  .assigned-translator__info-content {
    flex-direction: column;
  }

  .assigned-translator__wrapper {
    padding: 10px 15px;
  }

  .assigned-translator__info-avatar-cont {
    padding: 0 20px 20px;
  }
}
</style>
